import type { Account, AccountUser, Directions, LngLat, TranslationKey } from '@nexdynamic/squeegee-common';
import { AlphanumericCharColourDictionary, JobOccurrenceStatus, Location, wait } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable, bindingMode, computedFrom, inject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import type * as leaflet from 'leaflet';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { DateTimePicker } from '../../Components/DateTimePicker/DateTimePicker';
import { ClientArchiveService } from '../../Customers/ClientArchiveService';
import { SqueegeeLocalStorage } from '../../Data/SqueegeeLocalStorage';
import { DayPilotSettingsDialog } from '../../Dialogs/DayPilotSettingsDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { NavigationDialog } from '../../Dialogs/Navigation/NavigationDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { MenuBarActionsEvent } from '../../Events/MenuBarActionsEvent';
import { DateFilterOption } from '../../Jobs/Filters/DateFilterOption';
import { PositionUpdatedEvent } from '../../Location/PositionUpdatedEvent';
import { Logger } from '../../Logger';
import type { IMenuBarAction } from '../../Menus/IMenuBarAction';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { ScheduleByGroup } from '../../Schedule/Components/ScheduleByGroup';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { ScheduleService } from '../../Schedule/ScheduleService';
import { Api } from '../../Server/Api';
import { RethinkDbAuthClient } from '../../Server/RethinkDbAuthClient';
import { UserService } from '../../Users/UserService';
import type { Workload } from '../../Users/Workload';
import { Utilities } from '../../Utilities';
import type { IDayPilotOptions } from '../Components/IDayPilotOptions';
import type { RouteScheduleItem } from '../Components/RouteScheduleItem';
import type { OrderType } from '../DayPilot';
import { DayPilot } from '../DayPilot';
import { RouteOptimisationService } from '../RouteOptimisationService';

@inject(Router, DayPilot)
export class NotDone {
    public static _colours = new AlphanumericCharColourDictionary();

    protected scheduleDataDay: ScheduleByGroup = {};
    protected routeItems: Array<RouteScheduleItem> = [];
    protected scheduleItemsGroupedByRound: Array<{ key: string; items: Array<RouteScheduleItem> }> = [];
    public viewOptions?: IDayPilotOptions;
    protected loading = true;
    protected driveDistanceMetres: number | undefined;
    protected driveDurationSeconds: number | undefined;
    protected isOnlyWorker: boolean =
        ApplicationState.isInAnyRole('Worker') && !ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator', 'Planner']);
    protected optimisationDisabled = ApplicationState.getSetting('global.worker-optimisation-disabled', false) && this.isOnlyWorker;
    protected orderMethod: OrderType = this.optimisationDisabled
        ? 'planned'
        : ApplicationState.account.dayPilotDefaultOptimise
        ? 'optimal'
        : 'planned';
    protected travelDataLastUpdated: TranslationKey | undefined;

    @bindable({ defaultBindingMode: bindingMode.twoWay }) selectedItem: RouteScheduleItem | undefined;
    protected account: Account = ApplicationState.account;
    protected moreActions: Array<IMenuBarAction>;

    protected selectedRound?: string;
    protected rounds: Array<string> = [];

    private constructor(public router: Router, public dayPilot: DayPilot) {}

    private _dataRefreshedSub: Subscription;
    private _positionUpdatedSub: Subscription;
    private _applicationStateUpdatedSub: Subscription;

    protected Api = Api;

    protected isRouteOptimised = true; // Updated by checkRouteOptimised

    // FIXME: Remove this as element refrences slow the UI
    protected listContainer: HTMLUListElement;
    protected mapRef: leaflet.Map;

    private _refreshTimesTimer?: any;

    protected showWorkloadUserSelector = ApplicationState.multiUserEnabled && ApplicationState.isInAnyRole(['Admin', 'Planner', 'Owner']);
    protected currentUser = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;
    protected viewingUserId = RethinkDbAuthClient.session && RethinkDbAuthClient.session.email;

    protected isRestrictedDay = false;

    protected restrictedViewableDays = ApplicationState.getSetting<number | undefined>(
        'global.scheduling.mywork-days-visible-length',
        undefined
    );

    @computedFrom('viewingUserId')
    protected get overrideUser() {
        return this.viewingUserId === this.currentUser ? undefined : this.viewingUserId;
    }

    private getDataForDay(round: string) {
        const day = this.scheduleDataDay[round];
        return day ? Object.keys(day).map(key => day[key]) : [];
    }

    protected allCount = 0;

    @computedFrom('scheduleDataDay')
    protected get roundCounts() {
        return this.rounds.reduce<Record<string, number>>((acc, round) => {
            const roundData = this.scheduleDataDay[round];
            acc[round] = roundData ? Object.keys(roundData).length : 0;
            return acc;
        }, {});
    }

    protected getRoundCount(round: string | undefined) {
        if (round !== undefined) return Object.keys(this.scheduleDataDay[round]).length;
        let count = 0;
        for (const key in this.scheduleDataDay) {
            count += Object.keys(this.scheduleDataDay[key]).length;
        }
        return count;
    }

    protected async selectRound(round: string | undefined) {
        this.selectedRound = round;
        await this.refreshScheduleItemsList(false, undefined, this.orderMethod);
        this.selectedItem = undefined;
    }

    public async attached() {
        await this.refreshScheduleItemsList(undefined, undefined, this.orderMethod);
        await this.updateWorkloads();

        // this._scheduleChangedSub = ScheduleActionEvent.subscribe<ScheduleActionEvent>(async () => this.refreshScheduleItemsList());

        this._dataRefreshedSub = DataRefreshedEvent.subscribe(async (event: DataRefreshedEvent) => {
            if (event.hasAnyType('customers', 'joboccurrences', 'joborderdata')) {
                this.refreshScheduleItemsList(true, undefined, this.orderMethod);
                await this.updateWorkloads();
            }
        });

        this._positionUpdatedSub = PositionUpdatedEvent.subscribe(async () => this.refreshDriveTimesAndDistances());

        this._refreshTimesTimer = setInterval(() => {
            if (this.routeItems && this.routeItems.length > 0)
                RouteOptimisationService.refreshRouteItemTimes(
                    this.routeItems,
                    this.orderMethod,
                    this.viewingUserId
                        ? moment(
                              RouteOptimisationService.getUserOptimizationStartTime(
                                  this.viewingUserId,
                                  this.dayPilot.currentDate.toISOString()
                              )
                          )
                        : undefined
                );
        }, 60000);

        this.refreshViewOptions();

        if (!this.showWorkloadUserSelector) return;

        let done = false;
        let count = 0;
        do {
            const topItems = document.getElementsByClassName('leaflet-top');
            if (topItems.length === 0) {
                count++;
                await wait(50);
                continue;
            }
            for (let i = 0; i < topItems.length; i++) {
                topItems.item(i)?.classList?.add('has-workload');
            }
            done = true;
        } while (count < 5 && !done);
    }

    private refreshViewOptions() {
        const startLocation = SqueegeeLocalStorage.getItem('dayPilotStartLocation');
        const endLocation = SqueegeeLocalStorage.getItem('dayPilotEndLocation');

        this.viewOptions = {
            departureTimeMinutes: Number(SqueegeeLocalStorage.getItem('dayPilotDepartureTimeMinutes')) || 0,
            departureTimeHours: Number(SqueegeeLocalStorage.getItem('dayPilotDepartureTimeHours')) || 0,
            startLocation: startLocation ? JSON.parse(startLocation) : new Location(),
            endLocation: endLocation ? JSON.parse(endLocation) : new Location(),
        };
    }

    protected async switchToPlannedRoute() {
        this.selectedItem = undefined;
        await this.refreshScheduleItemsList(false, false, 'planned');
    }

    protected async manuallyOptimiseRoute() {
        if (!(await this.openOptimiseSettings())) return;
        await this.optimiseRoute();
    }

    protected async optimiseRoute() {
        this.selectedItem = undefined;
        await this.refreshScheduleItemsList(false, true, 'optimal');
        delete this.selectedItem;
    }

    protected delegateSelectDate = async () => {
        const datePickerDialog = new DateTimePicker(false, this.dayPilot.currentDate.format('YYYY-MM-DD'));

        datePickerDialog.init();
        await datePickerDialog.open();

        const weeks = ClientArchiveService.getMaxOccurrencesRetentionWeeks();
        const before12weeks = moment().subtract(weeks, 'weeks').startOf('weeks');
        const restrictedViewableDaysAsDate = moment().subtract(this.restrictedViewableDays, 'days');
        const minDate = before12weeks.isBefore(restrictedViewableDaysAsDate) ? before12weeks : restrictedViewableDaysAsDate;
        const maxDate = moment().add(this.restrictedViewableDays, 'days');

        if (!datePickerDialog.canceled) {
            if (
                moment(datePickerDialog.selectedDate).isSameOrBefore(before12weeks) &&
                !ApplicationState.stateFlags.devMode &&
                ApplicationState.getSetting('global.archive-occurrences-before-days', true)
            ) {
                return new NotifyUserMessage('day-pilot.date-selected-passed-archive-date');
            }

            const thisDay = moment(datePickerDialog.selectedDate);
            if (
                !this.isOnlyWorker ||
                this.restrictedViewableDays === undefined ||
                (thisDay.isSameOrAfter(minDate, 'day') && thisDay.isSameOrBefore(maxDate, 'day'))
            ) {
                this.isRestrictedDay = false;
            } else {
                this.isRestrictedDay = true;
            }

            SqueegeeLocalStorage.setItem('day-pilot-sticky-date', datePickerDialog.selectedDate);
            this.dayPilot.currentDate = moment(datePickerDialog.selectedDate);
            await this.refreshScheduleItemsList(false, undefined, this.orderMethod);
        }
    };

    public detached() {
        this._dataRefreshedSub && this._dataRefreshedSub && this._dataRefreshedSub.dispose();
        this._positionUpdatedSub && this._positionUpdatedSub.dispose();
        this._applicationStateUpdatedSub && this._applicationStateUpdatedSub.dispose();
        clearInterval(this._refreshTimesTimer);

        new MenuBarActionsEvent();
    }

    @computedFrom('account.businessAddress')
    protected get hasVerifiedBusinessAddress() {
        return !!this.account.businessAddress && !!this.account.businessAddress.lngLat && this.account.businessAddress.lngLat.length === 2;
    }

    protected setBusinessAddress() {
        ApplicationState.setBusinessAddress();
    }

    protected async openOptimiseSettings() {
        this.refreshViewOptions();
        if (!this.viewOptions) return;
        const viewOptions = Utilities.copyObject(this.viewOptions);
        const dialog = new DayPilotSettingsDialog(viewOptions, false);
        const dayPilotSettingsResult = await dialog.show(DialogAnimation.SLIDE);
        if (!dialog.cancelled) {
            if (
                dayPilotSettingsResult.departureTimeHours &&
                dayPilotSettingsResult.departureTimeMinutes &&
                (isNaN(dayPilotSettingsResult.departureTimeMinutes) || isNaN(dayPilotSettingsResult.departureTimeHours))
            ) {
                // error
            }
            SqueegeeLocalStorage.setItem('dayPilotDepartureTimeMinutes', String(dayPilotSettingsResult.departureTimeMinutes));
            SqueegeeLocalStorage.setItem('dayPilotDepartureTimeHours', String(dayPilotSettingsResult.departureTimeHours));
            SqueegeeLocalStorage.setItem('dayPilotStartLocation', JSON.stringify(dayPilotSettingsResult.startLocation));
            SqueegeeLocalStorage.setItem('dayPilotEndLocation', JSON.stringify(dayPilotSettingsResult.endLocation));

            return true;
        }
        return false;
    }

    protected async refreshScheduleItemsList(local = false, forceOptimise = false, switchToMethod?: OrderType) {
        this.loading = true;
        new LoaderEvent(true);
        try {
            this.scheduleDataDay = await this.getOccurrencesForCurrentDay();
            this.rounds = Object.keys(this.scheduleDataDay);
            let scheduleItems: Array<ScheduleItem> = [];
            if (!this.rounds.length || this.selectedRound === undefined) {
                for (const key in this.scheduleDataDay) {
                    scheduleItems = scheduleItems.concat(this.getDataForDay(key));
                }
            } else {
                scheduleItems = this.getDataForDay(this.selectedRound);
            }

            // WTF: if on refresh this round is now empty (excluding all, which is undefined) and is no longer available, switch to the first
            if (this.selectedRound !== undefined && !this.rounds.some(round => round === this.selectedRound))
                return await this.selectRound(this.rounds[0]);

            new NotifyUserMessage('notification.getting-travel-data');

            new LoaderEvent(false);

            const optimiseResponse = await RouteOptimisationService.getRouteItemsForScheduleDataDay(
                this.dayPilot.currentDate.format('YYYY-MM-DD'),
                this.viewingUserId,
                scheduleItems,
                forceOptimise,
                switchToMethod,
                local,
                undefined,
                undefined,
                this.viewOptions?.endLocation?.lngLat,
                this.viewingUserId
                    ? RouteOptimisationService.getUserOptimizationStartTime(this.viewingUserId, this.dayPilot.currentDate.toISOString())
                    : undefined
            );
            this.driveDurationSeconds = optimiseResponse.dayOrderData.totalDurationSeconds;
            this.driveDistanceMetres = optimiseResponse.dayOrderData.totalDistanceMetres;
            this.orderMethod = optimiseResponse.dayOrderData.sortMethod;
            this.routeItems = optimiseResponse.routeItems;
            this.allCount = this.getRoundCount(undefined);
            this.updateTravelDataUpdateText();
            this.loading = false;
        } catch (error) {
            Logger.error(`Error during refreshScheduleItemList`, error);
        } finally {
            new LoaderEvent(false);
        }
    }

    private async refreshDriveTimesAndDistances() {
        const currentLocationOverride = this.viewingUserId !== this.currentUser ? undefined : undefined;
        const data: Directions | undefined = await RouteOptimisationService.refreshDriveTimesAndDistances(
            this.routeItems,
            false,
            undefined,
            undefined,
            undefined,
            currentLocationOverride,
            undefined,
            this.viewingUserId
                ? RouteOptimisationService.getUserOptimizationStartTime(this.viewingUserId, this.dayPilot.currentDate.toISOString())
                : undefined
        );
        if (data) {
            this.driveDurationSeconds = data.totalDurationSeconds && data.totalDurationSeconds.value;
            this.driveDistanceMetres = data.totalDistanceMetres && data.totalDistanceMetres.value;
        }

        this.updateTravelDataUpdateText();
    }

    private updateTravelDataUpdateText() {
        this.travelDataLastUpdated = <TranslationKey>this.dayPilot.currentDate.format('ddd MMM'); // TODO: Store rank type
    }

    private getOccurrencesForAllUsersForCurrentDay() {
        const currentDateIso = this.dayPilot.currentDate.format('YYYY-MM-DD');
        return ScheduleService.getSchedule(currentDateIso, currentDateIso);
    }

    private async getOccurrencesForCurrentDay() {
        const currentDateIso = this.dayPilot.currentDate.format('YYYY-MM-DD');
        if (this.viewingUserId && this.viewingUserId !== this.currentUser) {
            return await ScheduleService.getScheduledJobsForUsersAndTeamsByDay(
                ScheduleService.getUserAndTeamIds(this.viewingUserId),
                this.dayPilot.currentDate,
                new DateFilterOption('', currentDateIso),
                [JobOccurrenceStatus.NotDone],
                'roundName',
                undefined,
                true
            );
        } else {
            return await ScheduleService.getScheduledJobsForCurrentUserByDay(
                this.dayPilot.currentDate,
                new DateFilterOption('', currentDateIso),
                [JobOccurrenceStatus.NotDone],
                'roundName'
            );
        }
    }

    public navigate() {
        if (this.selectedItem) {
            this.openNavigationDialog(this.selectedItem);
        }
    }

    public openNavigationDialog(location: RouteScheduleItem) {
        if (location) {
            const dialog = new NavigationDialog(location);
            dialog.showIfNeeded();
        }
    }

    // TODO: Review twhether this is needed...
    protected fitMapToLocationToggle = true;
    protected noLocationSelectedChanged(newValue: boolean) {
        if (newValue) this.fitMapToLocationToggle = !this.fitMapToLocationToggle;
    }

    @computedFrom('routeItems.length')
    protected get hasUnverifiedRouteItems() {
        return this.routeItems.some(ri => !ri.isVerified);
    }

    protected get showRoutingErrorMessage() {
        return (
            this.travelDataLastUpdated === undefined &&
            !this.hasUnverifiedRouteItems &&
            this.Api.isConnected &&
            this.hasVerifiedBusinessAddress &&
            !this.loading
        );
    }

    @computedFrom('isRouteOptimised')
    protected get showRouteNotOptimisedMessage() {
        return this.orderMethod === 'optimal' && !this.isRouteOptimised;
    }

    protected get showUnverifiedAddressesMessage() {
        return this.hasValidRouteItems && this.hasUnverifiedRouteItems;
    }

    protected get showOfflineMessage() {
        return (
            (this.travelDataLastUpdated === undefined || !this.hasVerifiedBusinessAddress) &&
            !this.hasUnverifiedRouteItems &&
            !this.Api.isConnected
        );
    }

    protected get hasValidRouteItems() {
        return this.travelDataLastUpdated !== undefined && this.routeItems.length;
    }

    protected getRoundBGColor(round: string) {
        return Utilities.textToColour(round);
    }

    protected workloads: Array<Workload>;
    protected userLocations: Array<LngLat | undefined>;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) protected selectedWorkLoad: Workload | undefined;
    protected selectedWorkLoadIndex = 0;
    protected selectUser(index: number) {
        this.selectedWorkLoadIndex = index;
        this.selectedWorkLoad = this.workloads[index];
        this.viewingUserId = (this.workloads[index].userOrTeam as AccountUser).email;
    }

    protected async selectedWorkLoadChanged(newWorkload?: Workload, oldWorkload?: Workload) {
        if (!oldWorkload) return;
        if (!this.selectedWorkLoad) {
            this.selectedWorkLoadIndex = 0;
            return;
        }

        const email = this.viewingUserId;
        const userName = this.selectedWorkLoad.userOrTeam ? this.selectedWorkLoad.userOrTeam.name : undefined;
        const index = this.workloads.findIndex(x => x.userOrTeam !== undefined && x.userOrTeam.name === userName);
        if (index > -1) this.selectedWorkLoadIndex = index;

        if (newWorkload?.userOrTeam?._id === oldWorkload?.userOrTeam?._id) return;
        if (newWorkload?.userOrTeam?._id === email /* viewingUserId is an email q(*_*)p */) return;
        await this.refreshScheduleItemsList(true);
    }

    private updateWorkloads() {
        const workloads = UserService.getWorksloadsForUsers(this.getOccurrencesForAllUsersForCurrentDay(), false, false);

        this.workloads = workloads;
        if (!this.selectedWorkLoad) {
            this.selectedWorkLoad = workloads.find(w =>
                w.userOrTeam?.resourceType === 'accountuser' ? (w.userOrTeam as AccountUser).email === this.viewingUserId : false
            );
            this.selectUser(this.selectedWorkLoad ? this.workloads.indexOf(this.selectedWorkLoad) : 0);
        } else this.selectedWorkLoad = workloads.find(workload => workload.userOrTeam?.name === this.selectedWorkLoad?.userOrTeam?.name);
    }
}
