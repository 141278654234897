import { bindable, computedFrom } from 'aurelia-framework';
import './custom-field-circular-progress.scss';

export class CircularProgress {
    @bindable items: Array<{ completed: boolean }> = [];

    private readonly circleCircumference = 2 * Math.PI * 40; // 2πr for radius 40

    @computedFrom('items')
    get completedItems(): number {
        return this.items.filter(item => item.completed).length;
    }

    @computedFrom('items')
    get totalItems(): number {
        return this.items.length;
    }

    @computedFrom('completedItems', 'totalItems')
    get progressOffset(): number {
        if (this.totalItems === 0) return this.circleCircumference;
        const progress = this.completedItems / this.totalItems;
        return this.circleCircumference * (1 - progress);
    }
}
