// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-field-progress-container {
  width: 100%;
  height: 100%;
  padding-right: 16px;
  cursor: pointer;
}

.custom-field-progress-circle {
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
}

.custom-field-progress-bg {
  fill: none;
  stroke: #e0e0e0;
  stroke-width: 6px;
}

.custom-field-progress-bar {
  fill: none;
  stroke: #007bff;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-dasharray: 251.32px;
  transition: stroke-dashoffset 0.3s ease;
}

.custom-field-progress-text {
  font-size: xx-large;
  fill: #333;
  font-weight: bold;
  text-anchor: middle;
  dominant-baseline: middle;
}`, "",{"version":3,"sources":["webpack://./src/Customers/Components/custom-field-circular-progress.scss"],"names":[],"mappings":"AAOA;EACI,WAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AANJ;;AASA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,iBAAA;AANJ;;AASA;EACI,UAAA;EACA,eAtB4B;EAuB5B,iBArBwB;AAe5B;;AASA;EACI,UAAA;EACA,eA7B0B;EA8B1B,iBA3BwB;EA4BxB,qBAAA;EACA,0BA3ByB;EA4BzB,uCAAA;AANJ;;AASA;EACI,mBAAA;EACA,UApCsB;EAqCtB,iBAAA;EACA,mBAAA;EACA,yBAAA;AANJ","sourcesContent":["$custom-field-progress-color: #007bff;\n$custom-field-background-color: #e0e0e0;\n$custom-field-text-color: #333;\n$custom-field-stroke-width: 6px;\n$custom-field-radius: 40px;\n$custom-field-circumference: 2 * 3.1415 * $custom-field-radius; // 2πr\n\n.custom-field-progress-container {\n    width: 100%;\n    height: 100%;\n    padding-right: 16px;\n    cursor: pointer;\n}\n\n.custom-field-progress-circle {\n    width: 100%;\n    height: 100%;\n    max-width: 100px; // Keeps it visible at a reasonable size\n    max-height: 100px;\n}\n\n.custom-field-progress-bg {\n    fill: none;\n    stroke: $custom-field-background-color;\n    stroke-width: $custom-field-stroke-width;\n}\n\n.custom-field-progress-bar {\n    fill: none;\n    stroke: $custom-field-progress-color;\n    stroke-width: $custom-field-stroke-width;\n    stroke-linecap: round;\n    stroke-dasharray: $custom-field-circumference;\n    transition: stroke-dashoffset 0.3s ease;\n}\n\n.custom-field-progress-text {\n    font-size: xx-large;\n    fill: $custom-field-text-color;\n    font-weight: bold;\n    text-anchor: middle;\n    dominant-baseline: middle;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
