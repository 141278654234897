import type {
    Alert,
    AuthorisedUser,
    IDataStatic,
    ISession,
    Job,
    JobOccurrence,
    StoredObject,
    StoredObjectResourceTypes,
    Transaction,
} from '@nexdynamic/squeegee-common';
import { CommonApplicationState, JobOccurrenceStatus, SyncMode, TransactionType, wait } from '@nexdynamic/squeegee-common';
import moment from 'moment';
import { AlertCountUpdateEvent } from '../Alerts/AlertCountUpdateEvent';
import { AlertDetailsDialog } from '../Alerts/AlertDetailsDialog';
import { ApplicationState } from '../ApplicationState';
import { AuditManager } from '../AuditManager';
import { Cache } from '../Cache';
import { ClientArchiveService } from '../Customers/ClientArchiveService';
import { DialogAnimation } from '../Dialogs/DialogAnimation';
import { Prompt } from '../Dialogs/Prompt';
import { ApplicationStateUpdatedEvent } from '../Events/ApplicationStateUpdatedEvent';
import { BGLoaderEvent } from '../Events/BGLoaderEvent';
import { DataRefreshedEvent } from '../Events/DataRefreshedEvent';
import { LoaderEvent } from '../Events/LoaderEvent';
import { NewDataEvent } from '../Events/NewDataEvent';
import { PermissionsUpdateEvent } from '../Events/PermissionsUpdateEvent';
import { GlobalFlags } from '../GlobalFlags';
import { JobOccurrenceService } from '../Jobs/JobOccurrenceService';
import { Logger } from '../Logger';
import { ActionableEvent } from '../Notifications/ActionableEvent';
import { NotifyUserMessage } from '../Notifications/NotifyUserMessage';
import { ScheduleService } from '../Schedule/ScheduleService';
import { Api } from '../Server/Api';
import { RethinkDbAuthClient } from '../Server/RethinkDbAuthClient';
import { SqueegeeClientSocketApi } from '../Server/SqueegeeClientSocketApi';
import { Stopwatch } from '../Stopwatch';
import { Utilities } from '../Utilities';
import type { DataChangeNotificationType } from './DataChangeNotificationType';
import type { DataStoreManager } from './DataStoreManager';
import { DexieDataStoreManager } from './DexieDataStoreManager';
import { SQLiteDataStoreManager } from './SQLite/SQLiteDataStoreManager';
import { SqueegeeLocalStorage } from './SqueegeeLocalStorage';
import { hasTransactionUpdatesAwaitingApiProcessing } from './protectAgainstUpdatesToAwaitingApiProcessing';

export class Data {
    public static async cleanUpConfirmedDeletesBackground(deleted: string[]) {
        if (!Data.dataStoreManager) return;
        const clone = [...deleted];
        while (clone.length) {
            await Data.dataStoreManager.deleteAllFromDataStore(clone.splice(0, 250));
        }
    }
    public static async checkAndSetDataEmailOverride() {
        try {
            const query = document.location.search
                ?.replace(/^\?/, '')
                .split('&')
                .reduce((all, pair) => {
                    const [key, value] = pair.split('=');
                    all[key] = value;
                    return all;
                }, {} as Record<string, string>);
            const dataEmail = query.dataEmail;
            if (!dataEmail) return;

            let accounts = Api.cachedAccounts;
            if (!accounts) accounts = await Api.getAccounts();

            if (!accounts || !accounts.length) return;

            const selectedAccount = accounts && accounts.find(x => x.dataEmail === dataEmail);
            ApplicationState.setAccountOverride(selectedAccount);

            let url = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

            delete query.dataEmail;

            if (Object.keys(query).length)
                url += `?${Object.keys(query)
                    .map(x => `${x}=${query[x]}`)
                    .join('&')}`;
            window.history.replaceState(undefined, document.title, url);
        } catch (error) {
            Logger.error('Failed to check and set the data email override', error);
        }
    }

    static dataStoreManager: DataStoreManager | undefined;

    public static setUniqueExternalId(storedObject: StoredObject, prefix?: string) {
        // TODO: Remove this when we server code no longer filters out invoices with an external id.
        // WTF: Prevents invoices filtered out on processing
        if (storedObject.resourceType === 'transactions') {
            return;
        }

        if (!prefix) prefix = storedObject.resourceType.substring(0, 3).toUpperCase();
        const code = Data.generateUniqueExternalId(storedObject._id, prefix);
        if (code) storedObject._externalId = code;
        return code;
    }

    private static generateUniqueExternalId(storedObjectId: string, prefix?: string) {
        let externalIdIsUnique: boolean;
        let limit = 100;
        do {
            const code = Utilities.generateUniqueRef(prefix);
            externalIdIsUnique = Data.isExternalIdUnique(storedObjectId, code);
            if (externalIdIsUnique) return code;
        } while (limit--);
    }

    private static externalIds: Record<string, string> = {};
    static isExternalIdUnique(storedObjectId: string, externalId: string) {
        const matchingInternalId = Data.externalIds[externalId];
        const codeIsUnique = !matchingInternalId || matchingInternalId === storedObjectId;
        return codeIsUnique;
    }

    private static _initialised: Promise<void> | undefined;
    public static reset() {
        this._initialised = undefined;
    }
    public static async init() {
        if (this._initialised) return this._initialised;
        this._initialised = new Promise<void>(async (resolve, reject) => {
            try {
                const stopwatch = new Stopwatch('Data Init');

                stopwatch.lap('Initialising Squeegee data');

                new LoaderEvent(true);
                await Data.checkAndSetDataEmailOverride();

                if (!RethinkDbAuthClient.session) throw 'User not yet signed in, cannot initialise database.';

                const accountOverride = ApplicationState.getAccountOverride();
                if (!accountOverride)
                    await Data.refreshAccountOverride(RethinkDbAuthClient.session.defaultAccount || RethinkDbAuthClient.session.email);
                else Data.refreshAccountOverride(accountOverride.dataEmail);

                // WTF: If this doesn't compile then data doesn't  implement IDataStatic correctly.
                const compileCheck: IDataStatic = Data;
                Logger.info('Data safe type checking', compileCheck);

                await Data.initDataStore(ApplicationState.dataEmail);

                stopwatch.lap('Completed initialising Squeegee data');

                return resolve();
            } catch (error) {
                return reject(error);
            } finally {
                new LoaderEvent(false);
            }
        });

        return await this._initialised;
    }

    public static async cleanUp() {
        Data.archiveExpiredAlerts();
    }

    private static _storedObjectMemoryOptimiser = (storedObject: StoredObject) => {
        return storedObject;
        // if (storedObject.resourceType !== 'notifications') return storedObject;
        // // Clean up notifications so they dont use up memory
        // const cleanNotification: Notification = { ...storedObject } as unknown as Notification;
        // cleanNotification.message = '';
        // return cleanNotification;
    };

    private static archiveExpiredAlerts() {
        const alertRetentionPeriodWeeks = ApplicationState.getSetting<number>('global.alert-retention-weeks', 2);
        const hasExpired = (alert: Alert) => moment(alert.createdDate).isBefore(moment().subtract(alertRetentionPeriodWeeks, 'weeks'));
        const alertsToDelete = Data.all<Alert>('alerts').filter(alert => hasExpired(alert));
        for (const alert of alertsToDelete) {
            alert._deleted = true;
            alert._archived = true;
        }
        if (alertsToDelete.length) Data.put(alertsToDelete, true, 'none');
    }

    public static async refreshAccountOverride(dataEmail: string) {
        const accounts = await Api.getAccounts();
        if (!accounts || !accounts.length) return;
        const selectedAccount = accounts && accounts.find(x => x.dataEmail === dataEmail);
        if (!selectedAccount) return;

        await ApplicationState.setAccountOverride(selectedAccount);
        return selectedAccount;
    }

    public static async initDataStore(email: string) {
        Logger.info('----- RUNNING initDataStore ------');
        // this._dataStoreManager = new DiskDataStoreManager('squeegee_data_', email);

        if (GlobalFlags.isAppleMobileApp) {
            this.dataStoreManager = new SQLiteDataStoreManager(`squeegee_dexie_${email}`, false, this._storedObjectMemoryOptimiser);
        } else {
            this.dataStoreManager = new DexieDataStoreManager(
                `squeegee_dexie_${email}`,
                ['&_id, resourceType, createdDate, updatedDate, timestamp', '&_id'],
                false,
                this._storedObjectMemoryOptimiser
            );
        }

        await this.dataStoreManager.initialise();
        for (const { _id, _externalId } of this.dataStoreManager.getCollectionDataArr()) {
            if (!_externalId) continue;
            Data.externalIds[_externalId] = _id;
        }
    }

    public static async initSync() {
        await Api.refreshConnectionMetadata();
        Data.fullSync(SyncMode.Full, 'none');
    }

    public static lastSuccessfullSyncTimeStamp = 0;
    private static _fullSyncPromise: Promise<boolean> | undefined;
    public static async fullSync(mode: SyncMode, notify: DataChangeNotificationType = 'lazy') {
        if (!Data.dataStoreManager) throw 'Cant sync without a data store manager.';
        if (!Data.fullSyncEnabled || !RethinkDbAuthClient.session) return false;
        const dataStoreManager = Data.dataStoreManager;
        await Api.initialised;
        await Data.init();

        let fullSyncPromise = Data._fullSyncPromise;
        if (fullSyncPromise === undefined) {
            const stopwatch = new Stopwatch('Data fullSync');
            fullSyncPromise = new Promise<boolean>(async resolve => {
                try {
                    await ApplicationState.checkAndUpdate();

                    const count = 1;
                    const delta: { [id: string]: number } = {};
                    for (const id of dataStoreManager.getCollectionDataKeys()) {
                        if (count > 0 && count % 1000 === 0) await wait();
                        if (id === CommonApplicationState.APPLICATION_STATE_ID) continue;

                        const deltaObject = dataStoreManager.getFromCollectionData(id);
                        if (!deltaObject) continue;

                        if (
                            mode === SyncMode.Partial &&
                            this.lastSuccessfullSyncTimeStamp &&
                            deltaObject.timestamp < this.lastSuccessfullSyncTimeStamp
                        )
                            continue;

                        delta[id] = deltaObject.timestamp;
                        if (deltaObject.resourceType === 'accountuser') continue;
                    }

                    const noLocalData = !delta || !Object.keys(delta).length;
                    const processChanges = noLocalData
                        ? (changes: Array<StoredObject>) => Data.put(changes, false, 'none')
                        : (changes: Array<StoredObject>) => Data.processRemoteStoredObjects(changes, true, notify);

                    const syncCompletion = await Api.getAllSyncStream(delta, mode, processChanges);

                    Cache.flush();

                    if (!syncCompletion) {
                        const resyncTO = setTimeout(() => Data.fullSync(mode), 30000);
                        await BGLoaderEvent.emit(true, `Problem syncing with the Squeegee™ Cloud, retrying in 30s.`);
                        new ActionableEvent(
                            ApplicationState.localise('sync.problem-will-retry'),
                            ApplicationState.localise('sync.retry-now'),
                            () => {
                                clearTimeout(resyncTO);
                                Data.fullSync(mode);
                            }
                        );
                        return resolve(false);
                    }

                    if (mode === SyncMode.Full || !this.lastSuccessfullSyncTimeStamp) {
                        await ClientArchiveService.autoArchive();
                    }
                    this.lastSuccessfullSyncTimeStamp = syncCompletion.nextSyncTimestamp;
                    stopwatch.lap('fullSync Api getAll');

                    if (syncCompletion.missing && syncCompletion.missing.length) {
                        await BGLoaderEvent.emit(true, `Sending ${syncCompletion.missing.length} changes to the Squeegee™ Cloud.`);
                        const unsyncedLocalData = {} as { [id: string]: StoredObject };
                        for (const id of syncCompletion.missing) {
                            const localObject = dataStoreManager.getFromCollectionData(id);
                            if (!localObject) continue;

                            unsyncedLocalData[id] = localObject;
                        }

                        await Api.queueSyncObjects(unsyncedLocalData);
                        await Api.processSyncQueue();
                    }

                    Api.updateItemsAwaitingSync();

                    const jobOrderData = ScheduleService.getJobOrderData();
                    if (!jobOrderData) await ScheduleService.initialisePlannedOrder();

                    await BGLoaderEvent.emit(false, ApplicationState.localise('data.synced-to-sqg-cloud'));
                } catch (error) {
                    setTimeout(() => Data.fullSync(mode), 30000);
                    if (
                        error &&
                        (error.name === 'AbortError' ||
                            (error.name === 'TypeError' &&
                                error.message &&
                                typeof error.message === 'string' &&
                                error.message.startsWith('network.connection-lost')))
                    ) {
                        // Don't care too much about these.
                        Logger.info('Abort in full sync', error);
                        await BGLoaderEvent.emit(false, 'data.sync-aborted-retry-30s');
                    } else {
                        Logger.error('Error in full sync', error);
                        await BGLoaderEvent.emit(false, 'data.sync-errord-retry-30s');
                    }
                    return resolve(false);
                } finally {
                    delete Data._fullSyncPromise;
                    new AlertCountUpdateEvent();
                    Data.reportDatabaseUsage();
                    resolve(true);
                }
            });

            Data._fullSyncPromise = fullSyncPromise;
        }

        return fullSyncPromise;
    }

    public static fullSyncEnabled = true;
    public static async processRemoteStoredObjects(
        remoteObjects: StoredObject | Array<StoredObject>,
        isFullSync: boolean,
        notify: DataChangeNotificationType = 'lazy'
    ): Promise<void> {
        if (!Array.isArray(remoteObjects)) remoteObjects = [remoteObjects];
        if (!remoteObjects.length) return;
        if (!Data.dataStoreManager) throw 'Cant sync without a data store manager.';
        const dataStoreManager = Data.dataStoreManager;
        const remoteObjectsToUpdateLocally = [] as Array<StoredObject>;
        const remoteObjectsResolvedWithLocal = [] as Array<StoredObject>;
        const localObjectsNeededOnServer = {} as { [id: string]: StoredObject };

        const today = moment().format('YYYY-MM-DD');

        const jobOccurrencesToResolve = new Array<JobOccurrence>();

        const newAlertsForMe = new Array<Alert>();

        let needsFullSync = false;

        for (const remoteObject of remoteObjects) {
            if (!remoteObject) continue;

            const id = remoteObject._id;
            if (!id) continue;

            if (id === 'auth') {
                const userAuthorisation = remoteObject as any as AuthorisedUser;
                if (
                    userAuthorisation.userEmail === (<ISession>RethinkDbAuthClient.session).email &&
                    ApplicationState.dataEmail === userAuthorisation.dataEmail
                ) {
                    await ApplicationState.setAccountOverride(userAuthorisation);
                }
                new PermissionsUpdateEvent();
                continue;
            }

            if (remoteObject.ownerEmail && remoteObject.ownerEmail !== ApplicationState.dataEmail) continue;

            const localObject = dataStoreManager.getFromCollectionData(remoteObject._id);

            try {
                // Not stored locally, store it.
                if (!localObject) {
                    // Removed this because it doesn't make sense and causes all sorts of problems.
                    // if (
                    //     allowAllocationForServerSidePayments &&
                    //     remoteObject.resourceType === 'transactions' &&
                    //     (remoteObject as Transaction).transactionSubType === 'payment.stripe' &&
                    //     (remoteObject as Transaction).customerId
                    // ) {
                    //     customersToRunAllocationOn.push((remoteObject as Transaction).customerId);
                    // }
                    remoteObjectsToUpdateLocally.push(remoteObject);

                    new NewDataEvent(remoteObject);

                    const email = RethinkDbAuthClient.session?.email;
                    const alert = (remoteObject.resourceType === 'alerts' && (remoteObject as Alert)) || undefined;
                    if (email && alert?.type === 'message' && !localObject && alert.audience?.includes(email)) {
                        newAlertsForMe.push(remoteObject as Alert);
                    }

                    continue;
                }

                // Same, leave it alone.
                if (remoteObject.timestamp === localObject.timestamp) continue;

                // Local must be newer, send it to the server.
                if (remoteObject.timestamp < localObject.timestamp) {
                    localObjectsNeededOnServer[localObject._id] = localObject;
                    continue;
                }

                // Remote one is newer, update it locally:

                // Deleted, make sure it's gone locally.
                if (remoteObject._deleted) {
                    if (localObject) remoteObjectsToUpdateLocally.push(remoteObject);
                    continue;
                }

                // Remote server processed transaction was voided here so void wins.
                if (
                    remoteObject.updatedByUser === 'system' &&
                    remoteObject.resourceType === 'transactions' &&
                    (localObject as Transaction).voidedId
                ) {
                    //WTF? To stop a cancelled local invoice being overwritten by a non-cancelled remote (i.e. processed)
                    const remoteTran = remoteObject as Transaction;
                    const localTran = localObject as Transaction;
                    if (!remoteTran.voidedId) {
                        remoteObjectsResolvedWithLocal.push(localTran);
                        continue;
                    }
                    remoteObjectsToUpdateLocally.push(remoteObject);
                    continue;
                }

                // Sort the job occurrences out but only on a full sync
                if (
                    localObject &&
                    remoteObject.resourceType === 'joboccurrences' &&
                    ((remoteObject as JobOccurrence).status === JobOccurrenceStatus.NotDone ||
                        (remoteObject as JobOccurrence).status === JobOccurrenceStatus.Skipped) &&
                    (localObject as JobOccurrence).status === JobOccurrenceStatus.Done &&
                    (localObject as JobOccurrence).invoiceTransactionId
                ) {
                    if (isFullSync) {
                        jobOccurrencesToResolve.push(remoteObject as JobOccurrence);
                        continue;
                    } else {
                        needsFullSync = true;
                        continue;
                    }
                }

                // Anything that was newer remotely and didn't need any special treatment.
                remoteObjectsToUpdateLocally.push(remoteObject);
                continue;
            } catch (error) {
                Logger.info('Failed to compare a local objects to the remote object remote object', {
                    details: error,
                    localObject,
                    remoteObject,
                });
            }
        }

        if (remoteObjectsToUpdateLocally.length) {
            try {
                await Data.put(remoteObjectsToUpdateLocally, false, notify);
            } catch (error) {
                Logger.error('Failed to store updated data after sync.', error);
            }
        }

        const additionalRemoteObjectsToUpdateLocally = [] as Array<StoredObject>;

        // WTF: Check the status of the job occurrences and update if necessary using the latest transactions from above.
        for (const jobOccurrence of jobOccurrencesToResolve) {
            const matchingTransactions = Data.all<Transaction>(
                'transactions',
                t => !t.voidedId && !!t.invoice && !!t.invoice.items && t.invoice.items.some(i => i.refID === jobOccurrence._id),
                {
                    customerId: (jobOccurrence as JobOccurrence).customerId,
                    transactionType: TransactionType.Invoice,
                }
            );

            if (
                matchingTransactions.length === 1 &&
                JobOccurrenceService.resolveOccurrenceStatus(jobOccurrence as JobOccurrence, today, matchingTransactions[0])
            ) {
                SqueegeeClientSocketApi;
                remoteObjectsResolvedWithLocal.push(jobOccurrence);
                continue;
            }

            additionalRemoteObjectsToUpdateLocally.push(jobOccurrence);
        }

        // WTF: Put corrected/resolved updates back to the server.
        try {
            if (additionalRemoteObjectsToUpdateLocally.length) await Data.put(additionalRemoteObjectsToUpdateLocally, false, notify);
            if (remoteObjectsResolvedWithLocal.length) await Data.put(remoteObjectsResolvedWithLocal, true, notify);
        } catch (error) {
            Logger.error('Failed to store resolved data after sync.', error);
        }

        try {
            Api.queueSyncObjects(localObjectsNeededOnServer);
        } catch (error) {
            Logger.error('Failed to queue out of date or missing remote data after sync.', error);
        }

        if (needsFullSync) return void wait(5000).then(() => Data.fullSync(SyncMode.Full));

        if (!newAlertsForMe.length) return;

        if (newAlertsForMe.length === 1) {
            const alert = newAlertsForMe[0];
            new AlertDetailsDialog(alert).show(DialogAnimation.SLIDE_UP);
        } else {
            new Prompt('alert-message.go-to-alerts-title', 'alert-message.go-to-alerts-description', {
                localisationParams: { count: newAlertsForMe.length.toString() },
                okLabel: 'general.yes',
                cancelLabel: 'general.no',
            })
                .show()
                .then(viewAlerts => viewAlerts && ApplicationState.navigateTo('alerts'));
        }
    }
    public static getBackup() {
        if (!Data.dataStoreManager) throw 'Cant get backup without a data store manager.';
        return Data.dataStoreManager.getCollectionDataArr();
    }
    public static async deleteAllData(includingAccountUsers = false, removeSettings = false) {
        const currentData = Data.all('').filter(
            x =>
                x._id !== CommonApplicationState.APPLICATION_STATE_ID &&
                (includingAccountUsers || x.resourceType !== 'accountuser') &&
                (removeSettings || x.resourceType !== 'settings')
        );
        await Data.delete(currentData);
    }

    public static async removeLocalData(removeAllDataStoresFromDisk = false) {
        if (!Data.dataStoreManager) throw 'Cant get removeLocalData without a data store manager.';
        if (removeAllDataStoresFromDisk) await Data.dataStoreManager.removeAllDataStoresFromDisk();
        await SqueegeeLocalStorage.clear();
        Data.dataStoreManager.resetCollectionData();
    }

    public static async clearLocalStorage() {
        await SqueegeeLocalStorage.clear();
        Data.deleteCookies();
    }

    public static deleteCookies() {
        try {
            const cookies = document.cookie.split(';');

            for (const cookie of cookies) Data.deleteCookie(cookie.split('=')[0]);
        } catch (error) {
            Logger.error('Failed to delete all cookies.', error);
        }
    }

    public static deleteCookie(name: string) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    public static async importBackup(dbData: { [id: string]: StoredObject }) {
        const items = Object.keys(dbData).map(key => dbData[key]);
        try {
            await Data.put(items);
        } catch (error) {
            Logger.error(`Error during import backup for user`, error);
        }
        await Data.initSync();
    }

    // Read methods
    public static get<TObjectType extends Exclude<StoredObject, Job>>(id: string): TObjectType | undefined {
        if (!Data.dataStoreManager) throw 'Cant get without a data store manager.';
        const storedObject = Data.dataStoreManager.getFromCollectionData(id);
        return storedObject && !storedObject._deleted ? (storedObject as TObjectType) : undefined;
    }

    public static count<TObjectType extends Exclude<StoredObject, Job>>(
        resourceType: Exclude<StoredObjectResourceTypes | '', 'jobs'>,
        whereOrIndexFilter?: Partial<TObjectType> | ((item: TObjectType) => boolean),
        indexFilter?: Partial<TObjectType>
    ) {
        return Data.all(resourceType, whereOrIndexFilter, indexFilter).length;
    }

    public static exists<TObjectType extends Exclude<StoredObject, Job>>(
        resourceType: Exclude<StoredObjectResourceTypes | '', 'jobs'>,
        whereOrIndexFilter?: Partial<TObjectType> | ((item: TObjectType) => boolean),
        indexFilter?: Partial<TObjectType>
    ) {
        return !!Data.firstOrDefault(resourceType, whereOrIndexFilter, indexFilter);
    }

    public static firstOrDefault<TObjectType extends Exclude<StoredObject, Job>>(
        resourceType: Exclude<StoredObjectResourceTypes | '', 'jobs'>,
        whereOrIndexFilter?: Partial<TObjectType> | ((item: TObjectType) => boolean),
        indexFilter?: Partial<TObjectType>,
        defaultValue?: TObjectType
    ) {
        const items = Data.all(resourceType, whereOrIndexFilter, indexFilter);
        return (items && items.length && items[0]) || defaultValue;
    }

    public static all<TObjectType extends Exclude<StoredObject, Job>>(
        resourceType: Exclude<StoredObjectResourceTypes | '', 'jobs'>,
        whereOrIndexFilter?: Partial<TObjectType> | ((item: TObjectType) => boolean),
        indexFilter?: Partial<TObjectType>
    ): Readonly<Array<TObjectType>> {
        if (!Data.dataStoreManager) throw 'Cant get backup without a data store manager.';
        const dataStoreManager = Data.dataStoreManager;
        const where = typeof whereOrIndexFilter === 'function' ? whereOrIndexFilter : undefined;
        if (!indexFilter) indexFilter = typeof whereOrIndexFilter === 'function' ? indexFilter : whereOrIndexFilter;

        let items: Readonly<Array<TObjectType>>;

        if (resourceType) {
            const cacheKey = resourceType;

            let cachedItems = Cache.get<Readonly<Array<TObjectType>>>(cacheKey);
            if (!cachedItems) {
                cachedItems = dataStoreManager
                    .getCollectionDataKeys()
                    .map(key => dataStoreManager.getFromCollectionData(key) as TObjectType)
                    .filter(item => !item._deleted && item.resourceType === resourceType) as Readonly<Array<TObjectType>>;

                Cache.set(cacheKey, cachedItems, [resourceType], true);
            }

            items = cachedItems;

            if (indexFilter && Object.keys(indexFilter).length) {
                const keys = Object.keys(indexFilter) as Array<keyof TObjectType>;
                const index = Data.getDynamicIndex<TObjectType>(resourceType, items, keys);
                const indexValueKey = Data.getIndexValueKey<TObjectType>(keys, indexFilter);
                items = index[indexValueKey] || [];
            }
        } else {
            items = <Array<TObjectType>>Data.dataStoreManager
                .getCollectionDataKeys()
                .map(key => dataStoreManager.getFromCollectionData(key))
                .filter(item => !!item && !item._deleted);
        }
        if (where && !Array.isArray(where)) {
            if (where) items = items.filter(item => where(<TObjectType>item));
        }

        return items;
    }

    private static getDynamicIndex<TObjectType extends Exclude<StoredObject, Job>>(
        resourceType: StoredObjectResourceTypes,
        items: readonly TObjectType[],
        propertyNames: Array<keyof TObjectType>
    ) {
        const cacheKey = `${resourceType}:${propertyNames.sort().join(':')}`;
        let index: { [indexKey: string]: Array<TObjectType> };
        index = Cache.get(cacheKey);
        if (!index) {
            index = {};
            for (const item of items) {
                const indexValueKey = Data.getIndexValueKey<TObjectType>(propertyNames, item);
                if (!index[indexValueKey]) index[indexValueKey] = [];
                index[indexValueKey].push(item);
            }
            Cache.set(cacheKey, index, [resourceType], true);
        }
        return index;
    }

    private static getIndexValueKey<TObjectType extends Exclude<StoredObject, Job>>(
        propertyNames: (keyof TObjectType)[],
        item: Partial<TObjectType>
    ) {
        return propertyNames
            .map(propertyName => {
                const value = item[propertyName];
                if (value === undefined) return '__undefined';
                if (typeof value === 'string') return value as string;
                if (typeof value === 'number' || typeof value === 'boolean') return value.toString();
                if (value === null) return '__null';
                return '*';
            })
            .sort()
            .join(':');
    }

    public static isNew(storedObject: StoredObject) {
        return !storedObject.ownerEmail;
    }

    public static async put<TObjectType extends Exclude<StoredObject, Job>>(
        objectsToUpdate: TObjectType | Array<TObjectType>,
        local = true,
        notify?: DataChangeNotificationType,
        isTransaction = true
    ) {
        if (!Data.dataStoreManager) throw 'Cant put without a data store manager.';
        if (!notify) notify = 'immediate';

        if (!Array.isArray(objectsToUpdate)) objectsToUpdate = [objectsToUpdate];

        const existingApplicationStateTimestamp = (ApplicationState.instance && ApplicationState.instance.timestamp) || 0;

        // Pre update data update
        const now = moment();
        const isoUpdatedDate = now.format();
        const timestamp = now.valueOf();
        const databaseEmail = Data.dataStoreManager.name.replace('squeegee_dexie_', '');
        let applicationStateUpdated = false;
        const errors: Array<Error> = [];
        const resourceTypes = new Set<StoredObjectResourceTypes>();
        for (const objectToUpdate of objectsToUpdate) {
            if (objectToUpdate.resourceType) resourceTypes.add(objectToUpdate.resourceType);

            if (local) {
                const allowUpdateOverride = ((window as any).allowStatusUpdatesAwaitingApiProcessing = !!(window as any)
                    .allowStatusUpdatesAwaitingApiProcessing);
                if (hasTransactionUpdatesAwaitingApiProcessing(objectToUpdate) && !allowUpdateOverride) {
                    errors.push(
                        Error(
                            `Transaction state violation, transaction '${objectToUpdate._id}'. Can not update transaction awaiting API processing`
                        )
                    );
                }
            }
            const isApplicationState = objectToUpdate._id === CommonApplicationState.APPLICATION_STATE_ID;
            if (isApplicationState) applicationStateUpdated = true;
            const objectEmail = (
                (isApplicationState ? (objectToUpdate as any as CommonApplicationState).account.email : objectToUpdate.ownerEmail) || ''
            ).toLowerCase();
            if (objectEmail && databaseEmail !== objectEmail) {
                errors.push(
                    Error(
                        `Object ownership violation, object '${objectToUpdate._id}' owned by ${objectEmail} attempted to store in ${databaseEmail} local db`
                    )
                );
            }
        }

        if (errors.length) {
            new NotifyUserMessage('notifications.error-during-save');
            for (const error of errors) {
                Logger.error(error.message, error);
            }
            if (isTransaction) throw errors;
        }

        for (const objectToUpdate of objectsToUpdate) {
            const isApplicationState = objectToUpdate._id === CommonApplicationState.APPLICATION_STATE_ID;

            if (isApplicationState) {
                const newApplicationState = objectToUpdate as any as CommonApplicationState;
                if (newApplicationState.timestamp > existingApplicationStateTimestamp) {
                    ApplicationState.updateApplicationStateInstance(newApplicationState);
                }
                continue;
            }
            if (!local) continue;
            Data.updateStoredObjectMetaData(objectToUpdate, isoUpdatedDate, timestamp);
        }

        await Data.writeData(objectsToUpdate, local);
        Data.updateCollectionFromInboundItems(objectsToUpdate, local);

        if (local) Data.putToServer<TObjectType>(objectsToUpdate);

        Cache.removeDependentCache(Array.from(resourceTypes));

        if (notify !== 'none') {
            const objectMap: { [id: string]: StoredObject } = objectsToUpdate.reduce<Record<string, StoredObject>>((map, object) => {
                map[object._id] = object;
                return map;
            }, {});
            DataRefreshedEvent.emit(objectMap, notify === 'lazy');

            if (applicationStateUpdated) new ApplicationStateUpdatedEvent();
        }

        Api.updateItemsAwaitingSync();
        if (local && ApplicationState.stateFlags.devMode && objectsToUpdate.some(x => !x._deleted)) {
            Logger.info(
                `Saved`,
                objectsToUpdate.filter(x => !x._deleted)
            );
        }

        if (local && ApplicationState.stateFlags.devMode && objectsToUpdate.some(x => x._deleted)) {
            Logger.info(
                `Deleted`,
                objectsToUpdate.filter(x => x._deleted)
            );
        }
    }

    private static putToServer<TObjectType extends Exclude<StoredObject, Job>>(objectsToUpdate: Readonly<Array<TObjectType>>) {
        const objectsNeedingToBeQueued = {} as { [id: string]: StoredObject | null };
        let hasObjects = false;
        for (const nextStoredObject of objectsToUpdate) {
            if (nextStoredObject._id === CommonApplicationState.APPLICATION_STATE_ID) {
                const newApplicationState = <CommonApplicationState>(<any>nextStoredObject);
                Api.putApplicationState(newApplicationState);
            } else {
                hasObjects = true;
                objectsNeedingToBeQueued[nextStoredObject._id] = nextStoredObject;
            }
        }

        if (hasObjects) Api.queueSyncObjects(objectsNeedingToBeQueued);
    }

    private static async writeData(storedObjects: Readonly<Array<StoredObject>>, local: boolean) {
        if (!Data.dataStoreManager) throw 'Cant write without a data store manager.';
        if (local) {
            await Data.dataStoreManager.writeAllToDataStore(storedObjects);
        } else {
            const deleted = [] as Array<string>;
            const updated = [] as Array<StoredObject>;
            for (const o of storedObjects) {
                if (o._deleted) deleted.push(o._id);
                else updated.push(o);
            }
            deleted.length && (await Data.dataStoreManager.deleteAllFromDataStore(deleted));
            updated.length && (await Data.dataStoreManager.writeAllToDataStore(updated));
        }
    }

    public static async delete(
        items: Exclude<StoredObject, Job> | Array<Exclude<StoredObject, Job>>,
        local = true,
        notify: DataChangeNotificationType = 'immediate',
        archive = false
    ) {
        try {
            // If item is not an array and is not an object, return.
            if (!Array.isArray(items) && !items) return;

            // If item is an array and is empty, return.
            if (Array.isArray(items) && !items.length) return;

            if (!Array.isArray(items)) items = [items];
            for (const item of items) {
                item._deleted = true;
                if (archive) item._archived = archive;
            }
            await Data.put(items, local, notify);

            AuditManager.debouncedDeleteAudit(items);
        } catch (error) {
            Logger.error(`Unable to delete objects`, { error, items });
        }
    }

    public static updateStoredObjectMetaData(objectToUpdate: Exclude<StoredObject, Job>, isoUpdatedDate: string, timestamp: number) {
        if (!objectToUpdate._deleted && !objectToUpdate._externalId) Data.setUniqueExternalId(objectToUpdate);
        else if (objectToUpdate._deleted && objectToUpdate._externalId) delete Data.externalIds[objectToUpdate._externalId];

        objectToUpdate.updatedOnDevice = ApplicationState.deviceId;
        objectToUpdate.updatedDate = isoUpdatedDate;
        objectToUpdate.timestamp = timestamp;
        objectToUpdate.ownerEmail = ApplicationState.dataEmail;
        if (!objectToUpdate.createdDate) objectToUpdate.createdDate = objectToUpdate.updatedDate;
        if (!objectToUpdate.createdBy)
            objectToUpdate.createdBy =
                (RethinkDbAuthClient.session?.email || 'Client') +
                (RethinkDbAuthClient.session?.freeDevice ? `(${RethinkDbAuthClient.session?.supportUser || 'unknown support user'})` : '');
        objectToUpdate.updatedByUser =
            (RethinkDbAuthClient.session?.email || 'Client') +
            (RethinkDbAuthClient.session?.freeDevice ? `(${RethinkDbAuthClient.session?.supportUser || 'unknown support user'})` : '');
        if (!objectToUpdate.createdOnDevice) objectToUpdate.createdOnDevice = ApplicationState.deviceId;
    }

    private static updateCollectionFromInboundItems(storedObjects: Array<Exclude<StoredObject, Job>>, local: boolean) {
        if (!Data.dataStoreManager) throw 'Cant update cllection without a data store manager.';
        for (const storedObject of storedObjects) {
            if (storedObject._externalId) {
                if (storedObject._deleted && !storedObject._archived) {
                    delete Data.externalIds[storedObject._externalId];
                } else {
                    Data.externalIds[storedObject._externalId] = storedObject._id;
                }
            }

            if (storedObject._deleted && !local) {
                Data.dataStoreManager.deleteFromCollectionData(storedObject._id);
                continue;
            }

            Data.dataStoreManager.storeInCollectionData(storedObject._id, storedObject);
        }
    }

    public static reportDatabaseUsage() {
        try {
            const lastRecordedTime = SqueegeeLocalStorage.getItem('last_time_recorded_database_usage');
            if (!lastRecordedTime || moment().diff(moment(lastRecordedTime), 'days') > 7) {
                Data.dataStoreManager?.reportDatabaseUsage();
                SqueegeeLocalStorage.setItem('last_time_recorded_database_usage', moment().format());
            }
        } catch (error) {
            Logger.error('Failed to report database usage', error);
        }
    }
}
