import type { TableData, TranslationKey } from '@nexdynamic/squeegee-common';
import { bindable, bindingMode } from 'aurelia-framework';
import { ApplicationState } from '../ApplicationState';
import type { IFabAction } from '../Components/Fabs/IFabAction';
import { openSystemBrowser } from '../Utilities';
import { CustomDialog } from './CustomDialog';

export class ReportDialog extends CustomDialog<string> {
    @bindable() updateFab = (fabActions: Array<IFabAction>) => {
        this.fabActions = fabActions;
    };
    @bindable({ defaultBindingMode: bindingMode.twoWay }) downloadUrl: string | null;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) downloadText: Extract<TranslationKey, 'general.download'> | null;
    constructor(
        protected readonly reportTitle: TranslationKey,
        protected readonly reportDescription: TranslationKey | undefined,
        protected readonly report: Array<TableData>,
        protected readonly sortBy: string,
        protected readonly sortAscending: boolean
    ) {
        super('reportDialog', './ReportDialog.html', ApplicationState.localise(reportTitle), {
            okLabel: '',
            cancelLabel: '',
            coverViewport: true,
            disableScroll: true,
        });
    }
    protected download = () => {
        if (!this.downloadUrl) return;
        openSystemBrowser(this.downloadUrl);
    };
    public delegateOk = () => this.ok();
}
