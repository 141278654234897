import type { MetadataCollectionSchema, MetadataCollectionValue, StoredObject, TranslationKey } from '@nexdynamic/squeegee-common';
import { getMetadataCollectionValuesId } from '@nexdynamic/squeegee-common';
import type { Subscription } from 'aurelia-event-aggregator';
import { bindable } from 'aurelia-framework';
import { Data } from '../../Data/Data';
import { AureliaReactComponentDialog } from '../../Dialogs/AureliaReactComponentDialog';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import type { MetadataCompleteDialogProps } from '../../ReactUI/CustomFields/CompleteCustomFields/dialog/CustomFieldsCompletionDialog';
import { CustomFieldsCompletionDialog } from '../../ReactUI/CustomFields/CompleteCustomFields/dialog/CustomFieldsCompletionDialog';
import { MetadataCollectionService } from '../../ReactUI/CustomFields/MetadataCollectionService';
import { t } from '../../t';

export class CustomFieldListItem {
    @bindable schemaId: string;
    @bindable storedObject: StoredObject | undefined;

    protected collectionSchema: MetadataCollectionSchema | undefined;

    protected completedFields: Array<{
        completed: boolean;
    }> = [];

    protected collectionValue: MetadataCollectionValue | undefined;
    private _dataRefreshedSub: Subscription;

    protected attached() {
        this.collectionSchema = Data.get<MetadataCollectionSchema>(this.schemaId);
        if (!this.collectionSchema || !this.storedObject) return;

        this._dataRefreshedSub = DataRefreshedEvent.subscribe((event: DataRefreshedEvent) => {
            if (event.hasAnyType('metadatacollection')) {
                const collection = event
                    .filterByType<MetadataCollectionValue>('metadatacollection')
                    .find(collection => collection.schemaId === this.collectionSchema?._id);
                this.collectionValue = Object.assign({}, collection);
                this.setCollectionData(this.collectionValue, this.collectionSchema);
                this.checkAllRequiredFieldsCompleted();
            }
        });
        const collectionValueId = getMetadataCollectionValuesId({
            targetObjectId: this.storedObject._id,
            valuesSchemaId: this.schemaId,
        });
        const collectionValue = Data.get<MetadataCollectionValue>(collectionValueId);
        this.setCollectionData(collectionValue, this.collectionSchema);

        this.checkAllRequiredFieldsCompleted();
    }

    protected setCollectionData(collectionValue?: MetadataCollectionValue, schema?: MetadataCollectionSchema) {
        if (!this.storedObject || !schema) {
            console.log('No stored object or custom field found for custom field list item');
            return;
        }

        this.completedFields = Object.keys(schema.items)
            .filter(keyString => {
                const key = Number(keyString);
                if (collectionValue?.values[key]) return true;
                else return schema.items[key].active;
            })
            .map(key => {
                return { completed: collectionValue?.values[Number(key)] !== undefined };
            });

        if (collectionValue) this.collectionValue = collectionValue;
        this.collectionSchema = schema;
    }

    protected detached() {
        if (this._dataRefreshedSub) this._dataRefreshedSub.dispose();
    }

    protected async completeCustomFields() {
        if (!this.storedObject || !this.collectionSchema) {
            console.log('No customer or custom field found for custom field list item');
            return;
        }
        const dialog = new AureliaReactComponentDialog<boolean, MetadataCompleteDialogProps>({
            component: CustomFieldsCompletionDialog,
            dialogTitle: this.collectionSchema.title as TranslationKey,
            componentProps: {
                targetObject: this.storedObject,
                schemasToComplete: [this.collectionSchema],
            },
            isSecondaryView: true,
        });

        // dialog.saveButton = {
        //     save: () => dialog.ok(true),
        //     saveText: 'general.finish',
        // };

        await dialog.show();
    }

    protected warningLocalisation = t('custom-fields.required-items-incomplete');

    protected requiredFieldsCompleted: boolean;
    protected checkAllRequiredFieldsCompleted() {
        if (!this.collectionSchema || !this.storedObject) return;

        if (!this.collectionValue) {
            MetadataCollectionService.schemaHasRequiredFields(this.collectionSchema).then(hasRequiredFields => {
                this.requiredFieldsCompleted = !hasRequiredFields;
            });
            return;
        }

        this.requiredFieldsCompleted = MetadataCollectionService.allRequiredFieldsForSchemaAreCompleted(
            this.collectionSchema,
            this.collectionValue,
        );
    }
}
