import type { TCellValue, TranslationKey } from '@nexdynamic/squeegee-common';
import { Setting, TableData } from '@nexdynamic/squeegee-common';
import { ApplicationState } from '../../ApplicationState';
import { DataRefreshedEvent } from '../../Events/DataRefreshedEvent';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { Logger } from '../../Logger';
import { ReportsService } from '../ReportsService';

export class Reports {
    protected report: Array<TableData>;

    private _serverSideReportsSettingId = Setting.getId(ApplicationState.account.uuid, 'beta-features.server-side-reports');
    private _dataRefreshedEvent: any;

    public async attached() {
        await this.loadReports();

        this._dataRefreshedEvent = DataRefreshedEvent.subscribe<DataRefreshedEvent>(async e => {
            if (!e.updatedObjects[this._serverSideReportsSettingId]) return;

            this.report = [];
            this.loadReports();
        });
    }

    public async detached() {
        this._dataRefreshedEvent?.dispose();
    }

    protected availableReportsColumnHeader = ApplicationState.localise('reports.title');

    public async loadReports() {
        new LoaderEvent(true, true);
        try {
            const reportsTable = new TableData(
                '' as TranslationKey,
                [this.availableReportsColumnHeader, 'type'],
                [],
                undefined,
                (row: Array<TCellValue> & { click?: () => void }) => row.click && row.click()
            );

            const executableReports = await ReportsService.getExecutableReports();

            const showInfoIcons = executableReports.some(report => report.legacy || report.name.includes('_STAFF_ '));

            for (const report of executableReports) {
                const unlockedRestrictedReport = report.name.includes('_STAFF_ ');

                const name = ApplicationState.localise(report.name.replace('_STAFF_ ', '') as TranslationKey);

                let columnOne: string;

                if (showInfoIcons) {
                    columnOne = `<span style="display: none">${name}</span><div style="display: flex; align-items: center; gap: 4px;">`;

                    columnOne += '<div style="display: flex; flex-direction: column; align-items: center; line-height: 1;">';
                    if (unlockedRestrictedReport) {
                        columnOne +=
                            '<i title="Restricted report, made\nvisible during staff sign in." class="material-icons" style="color: #00b24a; vertical-align: text-top; font-size: small">lock_open</i>';
                    }
                    if (report.legacy) {
                        columnOne +=
                            '<i title="This is a legacy client rendered report and may be missing archived data." class="material-icons" style="color: #ff7700; vertical-align: text-top; font-size: small">history</i>';
                    }
                    if (!report.legacy && !unlockedRestrictedReport) {
                        columnOne += '<i class="material-icons" style="opacity: 0; vertical-align: text-top; font-size: small">info</i>';
                    }
                    columnOne += `</div>${report.logoImg}<div>${name}</div></div>`;
                } else {
                    columnOne = `<span style="display: none">${name}</span><div style="display: flex; align-items: center; gap: 4px;">${report.logoImg}<div>${name}</div></div>`;
                }

                const reportRow: Array<TCellValue> & { click?: () => void } = [columnOne, report.type];

                reportRow.click = report.show;

                reportsTable.addRow(reportRow);
            }

            reportsTable.rows.sort((x, y) => (x[1] < y[1] ? -1 : x[1] === y[1] ? 0 : 1));

            this.report = [reportsTable];
        } catch (error) {
            Logger.error('Error in attached on Reports', error);
        }
        new LoaderEvent(false);
    }
}
