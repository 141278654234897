import type {
    AutomaticPaymentTransactionSubType,
    Customer,
    ICustomerPaymentProvidersData,
    InvoiceTransaction,
    InvoiceTransactionSubType,
    JobOccurrence,
    PaymentAccount,
    PaymentTransaction,
    PaymentTransactionSubType,
    Transaction,
    TransactionProvider,
    TranslationKey,
} from '@nexdynamic/squeegee-common';
import { PaymentMethod, TransactionType, getTaxConfig, sortByDateDesc, to2dp, uuid } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import moment from 'moment';
import { ApplicationState } from '../../ApplicationState';
import { ChargeCustomerService } from '../../Charge/ChargeCustomerService';
import type { IPaymentMethodDefinition, TPaymentMethodType } from '../../Charge/IPaymentMethodDefinition';
import { getPaymentMethods } from '../../Charge/PaymentMethods';
import { chargeUsingSquareReader } from '../../Charge/Square/chargeUsingSquareReader';
import type { IFabAction } from '../../Components/Fabs/IFabAction';
import { Data } from '../../Data/Data';
import { CustomDialog } from '../../Dialogs/CustomDialog';
import { DialogAnimation } from '../../Dialogs/DialogAnimation';
import { Prompt } from '../../Dialogs/Prompt';
import { Select } from '../../Dialogs/Select';
import { SendMessageToCustomer } from '../../Dialogs/SendMessageToCustomer';
import { LoaderEvent } from '../../Events/LoaderEvent';
import { InvoiceService } from '../../Invoices/InvoiceService';
import { NotifyUserMessage } from '../../Notifications/NotifyUserMessage';
import type { ScheduleItem } from '../../Schedule/Components/ScheduleItem';
import { Api } from '../../Server/Api';
import { MessageTemplates } from '../../Settings/MessageTemplates';
import { TransactionService } from '../../Transactions/TransactionService';
import { Utilities } from '../../Utilities';
import { t } from '../../t';
import { CustomerService } from '../CustomerService';
import { checkCustomerPaymentStatuses } from '../methods/checkCustomerPaymentStatuses';
import { CustomerDialog } from './CustomerDialog';

export class CustomerCreditDialog extends CustomDialog<Transaction> {
    @bindable() public paymentAmount = 0;

    public reference: string;

    public paymentDate: string = moment().format('YYYY-MM-DD');
    protected whatToPayOption:
        | 'fullBalance'
        | 'unpaidInvoices'
        | 'new-invoice'
        | 'other'
        | 'occurrence'
        | 'overpayment'
        | 'prepayment'
        | undefined;
    protected selectedPaymentMethod: IPaymentMethodDefinition | undefined;
    protected invoices: Array<Transaction> = [];
    protected selectedInvoiceMap = {} as { [_id: string]: boolean };
    protected hidePaymentOptions: boolean;
    protected hidePaymentMethods: boolean;
    protected readonly = false;
    protected provider?: TransactionProvider;
    protected providerId: string | undefined;
    protected occurrenceDescription: string;
    protected paymentMethodType: TPaymentMethodType;
    protected hasAnyChargeMethod = false;
    protected currentHostAndScheme = Api.currentHostAndScheme;
    protected label: string;
    protected isAdminOrAbove = ApplicationState.isInAnyRole(['Owner', 'Admin']);
    protected matchedInvoices?: Transaction[];
    protected occurrence?: JobOccurrence;
    protected invoiceTransaction?: Transaction;
    protected paymentMethods = getPaymentMethods(!ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']), this.customer);
    protected stripePaymentId: string = uuid();
    public paymentAccount?: PaymentAccount;
    public paymentAccountId?: string;
    protected hasStripeConnection = ApplicationState.account.stripePublishableKey;
    protected card?: {
        last4: string;
        brand: string;
        expiryMonth: string;
        expiryYear: string;
        id: string;
        expired: boolean;
    };
    private _showContact =
        ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']) ||
        !ApplicationState.getSetting('global.worker-planner-hide-contact-details', false);
    protected get showContact() {
        return this._showContact;
    }

    public devOnly = ApplicationState.stateFlags.devMode;
    onlyOnePaymentType: boolean;
    public static createForCustomer(customer: Customer) {
        const balance = TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD'));
        const dialog = new CustomerCreditDialog(balance.amount, customer);
        new LoaderEvent(false);

        CustomerCreditDialog.defaultToAuto(dialog);
        CustomerCreditDialog.setPaymentMethodFromLastUsed(dialog);

        return dialog;
    }

    public static createForScheduleItem(scheduleItem: ScheduleItem) {
        const dialog = CustomerCreditDialog.createForCustomer(scheduleItem.customer);
        if (scheduleItem.price === undefined) {
            dialog.hidePaymentOptions = true;
            return dialog;
        }

        if (scheduleItem.occurrence.invoiceTransactionId) {
            const invoiceTransaction = TransactionService.getTransaction(scheduleItem.occurrence.invoiceTransactionId);
            if (invoiceTransaction && invoiceTransaction.invoice && !invoiceTransaction.invoice.paid)
                dialog.invoiceTransaction = invoiceTransaction;
        }

        dialog.occurrence = scheduleItem.occurrence;

        if (!scheduleItem.occurrence.invoiceTransactionId) {
            const { taxEnabled, priceIncludesTax, taxRate } = getTaxConfig(scheduleItem.customer, ApplicationState.account);
            if (taxEnabled && !priceIncludesTax && taxRate !== undefined)
                dialog.balance = to2dp((dialog.balance || 0) + scheduleItem.occurrence.price * (1 + taxRate));
            else dialog.balance = (dialog.balance || 0) + scheduleItem.occurrence.price;
        }

        CustomerCreditDialog.defaultToAuto(dialog);
        CustomerCreditDialog.setPaymentMethodFromLastUsed(dialog);

        return dialog;
    }

    public static createForInvoice(customer: Customer, invoiceTransactionIn: Transaction, charge: boolean) {
        const invoiceTransaction = Data.get<Transaction>(invoiceTransactionIn._id);
        if (!invoiceTransaction) {
            new NotifyUserMessage('invoice.no-longer-exists');
            return;
        }
        if (invoiceTransaction.invoice?.paid && invoiceTransaction.invoice?.paymentReference) {
            new NotifyUserMessage('invoice.already-been-paid');
            return;
        }
        const dialog = CustomerCreditDialog.createForCustomer(customer);

        const customerBalanceAFTERInvoice = TransactionService.getCustomerBalance(customer._id, moment().format('YYYY-MM-DD'));

        if (customerBalanceAFTERInvoice.amount <= 0) return dialog;

        if (invoiceTransaction.invoice && !invoiceTransaction.invoice.paid) dialog.invoiceTransaction = invoiceTransaction;

        if (customerBalanceAFTERInvoice.amount < invoiceTransaction.amount) {
            dialog.paymentAmount = customerBalanceAFTERInvoice.amount;
            dialog.whatToPayOption = 'other';
        }

        if (charge) CustomerCreditDialog.defaultToAuto(dialog);
        CustomerCreditDialog.setPaymentMethodFromLastUsed(dialog);

        return dialog;
    }

    private static defaultToAuto(dialog: CustomerCreditDialog) {
        if (!dialog.hasAnyChargeMethod || !dialog.customer.automaticPaymentMethod) return;
        const method = dialog.paymentMethods.find(x => x.provider === dialog.customer.automaticPaymentMethod);
        dialog.selectedPaymentMethod = !method?.unavailable ? method : undefined;
        if (method) dialog.paymentMethodType = 'Charge';
    }

    public static createForProviderPayment(
        customer: Customer,
        provider: TransactionProvider,
        providerPayment: Transaction,
        invoice?: Transaction,
        paymentAccount?: PaymentAccount
    ) {
        const amount = Math.abs(providerPayment.amount);
        if (!providerPayment.amount) return;

        const dialog = invoice ? this.createForInvoice(customer, invoice, false) : this.createForCustomer(customer);
        if (!dialog) return;
        dialog.hidePaymentMethods = true;
        dialog.readonly = true;
        dialog.paymentAccount = paymentAccount;

        InvoiceService.allocateBalanceToInvoices(customer._id).then(() => {
            dialog.invoices = Data.all<Transaction>('transactions', t => !!t.invoice && !t.invoice?.paid && t.customerId === customer._id, {
                transactionType: TransactionType.Invoice,
            }).slice();

            dialog.whatToPayOption = dialog.invoices.length ? 'unpaidInvoices' : 'other';
            if (dialog.invoices.length === 1) dialog.selectedInvoiceMap[dialog.invoices[0]._id] = true;
        });

        dialog.paymentMethodType = 'Record';
        const method = dialog.paymentMethods.find(x => x.method === PaymentMethod.bankTransfer);
        if (!method) throw 'Failed to find bank transfer payment method.';
        dialog.selectedPaymentMethod = method;
        dialog.paymentMethods = [method];

        dialog.paymentAmount = amount;

        dialog.providerId = providerPayment.externalIds?.[provider];
        dialog.paymentDate = providerPayment.date;
        dialog.provider = provider;
        dialog.paymentAccountId = paymentAccount?._id;
        dialog.updatePaymentAccount();
        return dialog;
    }

    updatePaymentAccount(force?: boolean) {
        if (!force && this.paymentAccount) return;
        this.paymentAccountId = undefined;
        if ((this.paymentMethodType === 'Record' || this.paymentMethodType === 'Refund') && this.selectedPaymentMethod?.id) {
            const defaultPaymentAccountId = ApplicationState.getSetting<string, PaymentTransactionSubType>(
                'global.payment-account',
                undefined,
                this.selectedPaymentMethod.id as PaymentTransactionSubType
            );
            this.paymentAccountId = defaultPaymentAccountId;
        }
    }

    private constructor(protected balance: number, protected customer: Customer) {
        super('customerCreditDialog', '../Customers/Components/CustomerCreditDialog.html', ``, {
            okLabel: '',
            cancelLabel: '',
            cssClass: 'customer-credit-dialog form-dialog credit-dialog',
            isSecondaryView: true,
            destructive: true,
        });

        const hasCustomAutomaticPaymentMethod = InvoiceService.hasCustomAutomaticPaymentMethod(customer);

        this.warnAutoPayment =
            (!!this.customer.automaticPaymentMethod &&
                ApplicationState.account.invoiceSettings.autoChargeInvoiceMinusCredit &&
                !!this.customer.takePaymentOnInvoiced &&
                (hasCustomAutomaticPaymentMethod ||
                    (this.customer.paymentProviderMetaData?.[this.customer.automaticPaymentMethod as keyof ICustomerPaymentProvidersData]
                        ?.sourceOrMandateId &&
                        (this.customer.paymentProviderMetaData?.[
                            this.customer.automaticPaymentMethod as keyof ICustomerPaymentProvidersData
                        ]?.status === 'active' ||
                            this.customer.paymentProviderMetaData?.[
                                this.customer.automaticPaymentMethod as keyof ICustomerPaymentProvidersData
                            ]?.status === 'pending')))) ||
            false;

        this.hasAnyChargeMethod = this.paymentMethods.some(x => x.type === 'Charge' && !x.unavailable);

        if (this.paymentMethods.length === 0) {
            new NotifyUserMessage('payments.none-enabled');
            throw new Error(t('payments.none-enabled'));
        }
        if (this.paymentMethods.every((x, _i, a) => x.type === a[0].type)) {
            this.paymentMethodType = this.paymentMethods[0].type;
            this.onlyOnePaymentType = true;
        }

        if (customer.paymentProviderMetaData?.stripe) {
            const id = customer.paymentProviderMetaData.stripe.sourceOrMandateId;
            const last4 = customer.paymentProviderMetaData.stripe.cardLast4 || '';
            const brand = customer.paymentProviderMetaData.stripe.cardBrand || '';
            const expiryMonth = customer.paymentProviderMetaData.stripe.cardExpMonth || '';
            const year = customer.paymentProviderMetaData.stripe.cardExpYear || '';
            let expired = false;
            if (expiryMonth && year) {
                expired = moment(`${year}-${expiryMonth}-01`).endOf('month').isBefore(moment());
            }

            this.card = {
                id,
                last4,
                brand,
                expiryMonth,
                expiryYear: year?.slice(-2),
                expired,
            };
        }
    }

    protected switchPaymentAmountSource() {
        if (this.whatToPayOption !== 'unpaidInvoices') {
            for (const invoice of this.invoices) this.selectedInvoiceMap[invoice._id] = false;
        } else if (this.readonly) {
            this.selectedInvoiceMap[this.invoices[0]._id] = true;
        }

        this.updateLabel();
    }

    protected async setType(type: TPaymentMethodType) {
        if (this.readonly) return;

        this.updatePaymentAccount(true);

        if (this.paymentMethodType === type) return;

        this.paymentMethodType = type;
        this.selectedPaymentMethod = undefined;
        if (this.paymentMethodType === 'Tips') this.whatToPayOption = 'other';
        this.updateLabel();

        this.setDefaultPaymentAccount();
    }

    private async setDefaultPaymentAccount() {
        if (this.paymentMethodType === 'Charge' && !this.hasAnyChargeMethod) {
            if (ApplicationState.isInAnyRole(['Owner', 'Admin'])) {
                await new Prompt('charge.methods-configure', 'does.not-have-automatic-payment-enable-here', {
                    cancelLabel: '',
                }).show();
            }
            return;
        }
    }

    protected get canViewCustomer() {
        return this.customer && ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator']);
    }

    protected getPricePlusTax(occurrence: JobOccurrence) {
        if (!occurrence.customerId) return occurrence.price;

        const customer = CustomerService.getCustomer(occurrence.customerId);
        if (!customer) return occurrence.price;

        const { taxEnabled, priceIncludesTax, taxRate } = getTaxConfig(customer, ApplicationState.account);
        if (taxEnabled && !priceIncludesTax && taxRate !== undefined) return to2dp(occurrence.price * (1 + taxRate));

        return occurrence.price;
    }
    protected get hasSelectedInvoice() {
        return Object.values(this.selectedInvoiceMap).some(Boolean);
    }

    protected get validatePayment() {
        if (!this.paymentAmount) return 'Please enter a payment amount.';
        if (!this.selectedPaymentMethod?.id) return 'Please select a payment method.';
        if (!this.hasSelectedInvoice && this.whatToPayOption === 'unpaidInvoices') return false;
        return true;
    }
    public showPaymentTypeButton(type: string) {
        return this.paymentMethods.some(x => x.type === type);
    }
    private updateLabel() {
        setTimeout(() => {
            if (!this.readonly) {
                switch (this.whatToPayOption) {
                    case 'fullBalance':
                        this.paymentAmount = this.balance;
                        break;
                    case 'occurrence':
                        this.paymentAmount = (this.occurrence && this.getPricePlusTax(this.occurrence)) || 0;
                        break;
                    case 'unpaidInvoices': {
                        if (!this.invoices) return;
                        let total = 0;

                        for (const invoice of this.invoices.filter(x => this.selectedInvoiceMap[x._id])) {
                            total += Math.abs(invoice.amount);
                        }

                        this.paymentAmount = total;
                        break;
                    }
                }
            }
            if (!this.paymentAmount) {
                this.label = ApplicationState.localise('validation.payment-amount-required');
                return;
            }
            if (!this.selectedPaymentMethod) {
                this.label = ApplicationState.localise('validation.payment-method-required');
                return;
            }
            const selectedPaymentMethod = this.selectedPaymentMethod;
            this.label =
                `${selectedPaymentMethod.type === 'Tips' ? 'Record' : selectedPaymentMethod.type} ${ApplicationState.currencySymbol()}${(
                    this.paymentAmount || 0
                ).toFixed(2)}` + `${selectedPaymentMethod.type === 'Charge' ? ' by ' : ' as '}${selectedPaymentMethod.name}`;
        }, 50);
    }

    protected async viewCustomer() {
        if (this.canViewCustomer) {
            if (this.customer) {
                const dialog = new CustomerDialog(this.customer);
                await dialog.show(DialogAnimation.SLIDE_UP);
            }
        }
    }

    public async init() {
        if (this.occurrence) {
            const date = this.occurrence.isoCompletedDate || this.occurrence.isoPlannedDate || this.occurrence.isoDueDate;
            this.occurrenceDescription = moment(date).format('ll');
        }

        if (!this.invoices?.length && !this.readonly) this.loadUnpaidInvoices();

        await this.loadValues();

        if (this.balance <= 0 && !this.occurrence && (!this.invoices || !this.invoices.length)) {
            this.whatToPayOption = 'other';
        }

        this.updateLabel();

        const actions = this.getCustomerActions();
        this._settings.fab = { actions };
        this.fabActions = actions;

        Api.isConnected && checkCustomerPaymentStatuses(this.customer._id);
    }

    private getCustomerActions(): Array<IFabAction> {
        const fabActions: Array<IFabAction> = [];

        if (this.customer && ApplicationState.isInAnyRole(['Owner', 'Admin', 'Creator'])) {
            fabActions.push({
                tooltip: 'actions.request-payment',
                actionType: 'action-request-payment',
                handler: this._delegateRequestPayment,
                roles: ['Owner', 'Admin', 'Creator'],
            });
        }

        return fabActions;
    }

    protected warnAutoPayment: boolean;

    private _delegateRequestPayment = () =>
        this.messageCustomer({
            sms:
                (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.smsPaymentRequest) ||
                MessageTemplates.instance.smsPaymentRequest.default,
            email:
                (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailPaymentRequest) ||
                MessageTemplates.instance.emailPaymentRequest.default,
            emailIsHtml: (ApplicationState.messagingTemplates && ApplicationState.messagingTemplates.emailPaymentRequestIsHtml) || false,
        });

    private async messageCustomer(messages?: { sms: string; email: string; emailIsHtml: boolean }) {
        if (!ApplicationState.isVerifiedForMessaging) throw new Error('Please verify your email to send messages');
        if (this.customer) {
            const notifyDialog = new SendMessageToCustomer(this.customer, messages);
            return await notifyDialog.show();
        }
    }

    private loadValues() {
        if (this.occurrence) {
            const amount = (this.occurrence.price || 0) - this.balance;
            this.paymentAmount = amount > 0 ? amount : 0;
            this.whatToPayOption = 'occurrence';
        } else if (!this.occurrence && this.invoiceTransaction && this.invoiceTransaction.invoice) {
            const providedInvoice = this.invoiceTransaction.invoice;
            const selectedInvoice =
                this.invoices && this.invoices.find(x => x.invoice && x.invoice.referenceNumber === providedInvoice.referenceNumber);
            if (selectedInvoice) {
                this.whatToPayOption = 'unpaidInvoices';
                // WTF: This is a dirty hack to get around Aurelia sub template repeating checkboxes initial check state.
                setTimeout(() => {
                    const label = document.getElementById(`inv-checkbox-${selectedInvoice._id}`);
                    label && label.click();
                }, 250);
            }
        }
    }

    protected unpaidInvoicesChanged(inv: Transaction) {
        if (this.readonly) {
            for (const invoice of this.invoices) this.selectedInvoiceMap[invoice._id] = invoice._id === inv._id;
        } else {
            this.selectedInvoiceMap[inv._id] = !this.selectedInvoiceMap[inv._id];
        }
        this.updateLabel();
    }

    protected getChargeDescription = () => {
        let occurrence = this.occurrence;
        const secondaryOccurrenceId = this.invoiceTransaction?.invoice?.items?.[0]?.refID;
        if (!occurrence && secondaryOccurrenceId) occurrence = Data.get<JobOccurrence>(secondaryOccurrenceId);
        const services = occurrence && occurrence.services && occurrence.services.length ? occurrence.services : undefined;
        const serviceInfo = services ? Utilities.localiseList(services.map(s => s.description as TranslationKey)) : '';

        const paymentDescription = `${serviceInfo ? ` ${serviceInfo}` : ''}${this.reference ? ' ' + this.reference.trim() : ''}`;

        return paymentDescription;
    };

    private _recording: boolean;
    public recordDelegate() {
        // WTF: running record() directly from click will allow double clicks to double trigger GC payment
        if (this._recording) return;
        this._recording = true;
        this.record();
    }

    protected recordingComplete: boolean;
    public async record(providerId?: string, paymentTransactionId?: string) {
        try {
            let tipAmount: number | undefined;
            const isValid = this.validateForm();
            if (isValid !== true) {
                new NotifyUserMessage(isValid);
                return;
            }
            if (!this.selectedPaymentMethod) {
                new NotifyUserMessage('no.valid-payment-method-selected-user-message');
                return;
            }

            new LoaderEvent(true);

            const additionalReference =
                (this.selectedPaymentMethod.id.startsWith('payment.tip.') ? this.selectedPaymentMethod.name : '') +
                (this.reference ? ` (${this.reference})` : this.getChargeDescription());

            const type = this.selectedPaymentMethod.tranType;
            const subType = this.selectedPaymentMethod.id;
            const provider = this.provider || this.selectedPaymentMethod.provider;
            const method = this.selectedPaymentMethod.method;

            const potectialSelectedInvoiceIds = Object.keys(this.selectedInvoiceMap).filter(x => this.selectedInvoiceMap[x]);
            const invoiceIds = this.invoiceTransaction ? [this.invoiceTransaction._id] : potectialSelectedInvoiceIds;

            let paymentTransaction: PaymentTransaction | InvoiceTransaction;

            if (provider === 'square') {
                new LoaderEvent(false);
                const customerId = this.customer._id;
                chargeUsingSquareReader(
                    {
                        customerId,
                        amount: this.paymentAmount,
                        invoiceIds,
                    },
                    async (paymentTransaction: PaymentTransaction) => {
                        await InvoiceService.allocateBalanceToInvoices(customerId);
                        this.ok(paymentTransaction);
                    }
                );
                return;
            } else if (provider === 'gocardless' || provider === 'stripe') {
                const selectedInvoices = this.invoices.filter(t => this.selectedInvoiceMap[t._id]);

                const indempotencyKey = InvoiceService.generateIdempotencyKey(this.occurrence, selectedInvoices);
                const occurrenceId = this.occurrence && this.occurrence._id;

                const invoiceItems = new Set<string>();

                if (!occurrenceId) {
                    selectedInvoices.forEach(i => {
                        if (i.invoice?.items) {
                            i.invoice.items.forEach(item => {
                                invoiceItems.add(item.description);
                            });
                        }
                    });
                }

                const extraItems =
                    invoiceItems.size > 1
                        ? `${Array.from(invoiceItems)[0]} +${invoiceItems.size - 1} ${ApplicationState.localise(
                              'general.more'
                          ).toLocaleLowerCase()}`
                        : '';
                const finalReference = [additionalReference, extraItems].filter(Boolean).join(' | ');

                paymentTransaction = TransactionService.createAutomaticPayment({
                    paymentProvider: provider,
                    type: subType as AutomaticPaymentTransactionSubType,
                    customerId: this.customer._id,
                    amount: this.paymentAmount,
                    additionalReference: finalReference.slice(0, 50),
                    initiatorReference: 'confirmed',
                    invoiceIds,
                    idempotencyKey: indempotencyKey,
                    jobOrOccurrenceId: occurrenceId,
                    paymentTransactionId,
                });
            } else if (type === TransactionType.Payment) {
                // Do we have something left over?

                new LoaderEvent(false);
                const selectedInvoices = this.invoices.filter(t => this.selectedInvoiceMap[t._id]);
                if (selectedInvoices.length) {
                    const total = selectedInvoices.length ? selectedInvoices.reduce((acc, cur) => acc + cur.amount, 0) : 0;
                    if (total > this.paymentAmount) {
                        new NotifyUserMessage('insufficient.funds.user-message' as TranslationKey);
                        return;
                    } else if (total < this.paymentAmount) {
                        const diff = this.paymentAmount - total;
                        const selOption = new Select(
                            `${diff.toFixed(2)} Overpayment` as TranslationKey,
                            [
                                {
                                    text: 'Leave as an Overpayment',
                                    value: 'overpayment',
                                },
                                {
                                    text: `Allocate as a Tip`,
                                    value: 'tip',
                                },
                            ],
                            'text',
                            'value',
                            undefined,
                            { allowCancel: true, isSecondaryView: true }
                        );

                        const result = await selOption.show();
                        if (!result) return;
                        if (result.value === 'tip') {
                            tipAmount = diff;
                        }
                    }
                }
                new LoaderEvent(true);

                paymentTransaction = TransactionService.createManualPayment(
                    subType as PaymentTransactionSubType,
                    method,
                    this.customer._id,
                    this.paymentAmount,
                    this.paymentDate,
                    additionalReference,
                    invoiceIds,
                    provider,
                    providerId,
                    this.paymentAccountId || this.paymentAccount?._id
                );
            } else if (type === TransactionType.Invoice) {
                paymentTransaction = TransactionService.createCreditNote(
                    this.paymentDate,
                    subType as InvoiceTransactionSubType,
                    this.customer._id,
                    this.paymentAmount,
                    additionalReference,
                    invoiceIds
                );
            } else {
                throw 'Unhandled type of transaction';
            }

            await ChargeCustomerService.completeLocalPayment(
                paymentTransaction,
                invoiceIds,
                this.whatToPayOption === 'new-invoice',
                tipAmount
            );

            this.recordingComplete = true;

            this.ok(paymentTransaction);
        } finally {
            this._recording = false;
            new LoaderEvent(false);
        }
    }

    public validateForm(): true | TranslationKey {
        if (!this.selectedPaymentMethod) return 'validation.payment-method-required';
        if ((Number(this.paymentAmount) || 0) === 0) return 'validation.payment-amount-required';
        if (!this.hasSelectedInvoice && this.whatToPayOption === 'unpaidInvoices') return 'validation.invoice-required';
        return true;
    }

    public loadUnpaidInvoices() {
        if (this.customer) {
            const invoiceTransactions = TransactionService.getCustomerInvoicesExcludingVoids(this.customer._id);
            this.invoices = invoiceTransactions.sort(sortByDateDesc).filter(i => !!i.amount && i.invoice && !i.invoice.paid);
        }
    }

    protected updatePaymentMethod = () => {
        this.updatePaymentAccount();
        this.updateLabel();
    };

    protected paymentDetailsUpdated = async (ok: boolean, paymentMethodId?: string, paymentTransactionId?: string) => {
        if (!ok) return new NotifyUserMessage('error.with-card-check-stripe-dashboard');
        this.recordingComplete = true;
        if (!paymentMethodId) return;

        if (!paymentTransactionId) return;

        await this.record(paymentMethodId, paymentTransactionId);

        return new NotifyUserMessage('payment.processed-successfully');
    };

    private getLastUsedPaymentMethod(): IPaymentMethodDefinition | undefined {
        if (!this.customer || !this.customer.lastPaymentMethod) return;
        const availablePaymentMethods = this.paymentMethods.filter(x => !x.unavailable);
        if (availablePaymentMethods.length === 1) {
            return availablePaymentMethods[0];
        }

        const customersLastPaymentMethod = this.customer.lastPaymentMethod;
        if (!customersLastPaymentMethod) return;
        const lastPaymentMethod = availablePaymentMethods.find(x => x.id === customersLastPaymentMethod);
        if (lastPaymentMethod) {
            return lastPaymentMethod;
        }
    }

    private static setPaymentMethodFromLastUsed(dialog: CustomerCreditDialog): void {
        if (!dialog.selectedPaymentMethod) {
            const lastUsedPaymentMethod = dialog.getLastUsedPaymentMethod();
            if (!lastUsedPaymentMethod) return;

            dialog.selectedPaymentMethod = lastUsedPaymentMethod;
            dialog.paymentMethodType = lastUsedPaymentMethod.type;
        }
    }
}
