import type { LngLat } from '@nexdynamic/squeegee-common';
import { bindable } from 'aurelia-framework';
import * as leaflet from 'leaflet';
import { SqueegeeLocalStorage } from '../../Data/SqueegeeLocalStorage';
import { animate } from '../../Utilities';
import { getMapBaseLayers } from './getMapBaseLayers';

export class PinnableMap {
    //PUBLIC
    @bindable() name = 'pinnable';
    @bindable() lngLat: LngLat;
    @bindable() currentPosition: LngLat;
    @bindable() onPinned: (lat: number, lng: number) => void;
    @bindable() onUnPinned: () => void;

    public mapElement: HTMLElement | undefined;

    private _map: L.Map;

    private static _defaultLatLng = new leaflet.LatLng(52, -2);
    public async attached() {
        await this.attachMap();

        const latLng = this.lngLat ? new leaflet.LatLng(this.lngLat[1], this.lngLat[0]) : PinnableMap._defaultLatLng;
        this.currentPin = this.createPin(latLng);
        this.onPinned && this.onPinned(latLng.lat, latLng.lng);
    }

    public detached() {
        this._map?.remove();
        delete this.mapElement;
    }

    private async attachMap() {
        await animate(50);
        if (this.mapElement) {
            this._map = new leaflet.Map(this.mapElement, {
                minZoom: 1,
                maxZoom: 18,
                attributionControl: false,
                zoomControl: false,
            });

            const mapLoaded = new Promise<leaflet.Map>(resolve => this._map.on('load', () => resolve(this._map)));

            const ready = new Promise<void>(resolve => this._map.once('moveend zoomend', () => resolve()));

            this._map.on('baselayerchange', e =>
                SqueegeeLocalStorage.setItem(`map-${this.name}-default-layer`, (e && (e as any).name) || null)
            );

            const layers = getMapBaseLayers();

            const currentLayerName = SqueegeeLocalStorage.getItem(`map-${this.name}-default-layer`) as keyof typeof layers;
            const currentLayer = (currentLayerName && layers[currentLayerName]) || layers['Hybrid Day'];

            this._map.addLayer(currentLayer);

            leaflet.control.layers(layers).addTo(this._map);

            this._map.setView(this.lngLat ? [this.lngLat[1], this.lngLat[0]] : PinnableMap._defaultLatLng, 15);

            (await mapLoaded) && (await ready);

            this.markCurrentPosition();
            this.bindClick();
        }
    }

    private currentPin?: leaflet.Marker;
    private bindClick() {
        this._map.on('click', this.onClick);
    }

    private onClick = (event: leaflet.LeafletMouseEvent) => {
        if (this.currentPin) {
            this.currentPin.remove();
        }
        this._map.panTo(event.latlng);
        this.currentPin = this.createPin(event.latlng);
        this.onPinned && this.onPinned(event.latlng.lat, event.latlng.lng);
    };

    private createPin(latLng: leaflet.LatLng) {
        return leaflet
            .marker(latLng, {
                title: 'Current Pin',
                icon: leaflet.divIcon({
                    html: `<i class="background-fill"></i>
                    <i class="marker material-icons">person_pin_circle</i>`,
                    className: 'pin-marker',
                    iconSize: [38, 38],
                    iconAnchor: [16, 38],
                }),
            })
            .addTo(this._map);
    }

    private markCurrentPosition() {
        leaflet
            .marker([this.currentPosition[1], this.currentPosition[0]], {
                title: 'Current Position',
                icon: leaflet.divIcon({
                    html: '<i class="material-icons md-24">my_location</i>',
                    className: 'current-position-marker',
                    iconSize: [24, 24],
                    iconAnchor: [12, 12],
                }),
            })
            .addTo(this._map);
    }

    protected recenter() {
        if (this.lngLat) {
            this._map.setView([this.currentPosition[1], this.currentPosition[0]], 15);
        }
    }
}
